import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './PageTitleGroup.module.scss';

const PageTitleGroup = ({ children, className, style }) => (
  <div className={cn(styles.pageTitleGroup, className)} style={style}>
    {children}
  </div>
);

PageTitleGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object
};

export default PageTitleGroup;
