import React, { useEffect } from 'react';
import cn from 'classnames';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { ParallaxProvider } from 'react-scroll-parallax';

// Layout component should come first in imports
// since it holds global styles and css reset which should be applied first
import Layout from 'components/Layout';
import ContentContainer from 'components/ContentContainer';
import PageContent from 'components/PageContent';
import PageSubtitle from 'components/PageSubtitle';
import PageTitle from 'components/PageTitle';
import PageTitleGroup from 'components/PageTitleGroup';
import Seo from 'components/seo';
import Button from 'components/Button';
import Contacts from 'components/ContactsSection';
import InfoSection from 'components/InfoSection';

import commissionIcon from 'images/selling-features/commission.png';
import dataTransferIcon from 'images/selling-features/data-transfer.png';
import paymentIcon from 'images/selling-features/payment.png';
import plansIcon from 'images/selling-features/plans.png';
import refundsIcon from 'images/selling-features/refunds.png';
import sellingIcon from 'images/selling-features/selling.png';

import categoryPricing from '../../constants/categoryPricing';

import styles from './Pricing.module.scss';

const Pricing = () => {
  useEffect(() => {
    Aos.init({ duration: 600 });
  }, []);

  return (
    <Layout>
      <Seo
        title="Hersteller-Konditionen"
        description="Verkaufen Sie auf dem weltweit ersten B2B2C Marktplatz · Produkte verkaufen · Digital Datensätze erhalten · Sichere Online Bezahlfunktionen nutzen"
      />
      <ParallaxProvider>
        <ContentContainer>
          <PageContent>
            <PageTitleGroup style={{ border: 'none' }}>
              <PageTitle>Für Hersteller & Anbieter</PageTitle>
              <PageSubtitle>
                Verkaufen Sie Ihre Produkte und Dienstleistungen online
              </PageSubtitle>
            </PageTitleGroup>

            <div className={styles.section} id="b2b2c">
              <h3 className={styles.sectionTitle}>B2B2C</h3>
              <h4 className={styles.sectionSubtitle}>Ihre Vorteile</h4>
              <p className={styles.sectionDescription}>
                Als Verkäufer werden Sie schnell entdecken, dass der FUNERAL MARKETPLACE{' '}
                <b>einzigartige Tools und Features</b> für das Wachstum Ihres Unternehmens
                bietet. Diese sind in Ihrem Konto enthalten und können für die flexible
                Verwaltung Ihres Geschäftes bei Nutzung eines eigenen Kundenservices und
                Marketings eingesetzt werden. Dies sind einige der Top-Vorteile des
                Verkaufens auf dem FUNERAL MARKETPLACE:
              </p>
              <div className={styles.innerSection}>
                <ul>
                  <li>
                    Verwalten Sie eine umfassende Produktdatenbank all Ihrer Produkte mit
                    einer zentralen Produkt-, Bild- und Datenverwaltung
                  </li>
                  <li>
                    Nutzen Sie das Angebot und die Attraktivität eines gesamten digitalen
                    Marktplatzes
                  </li>
                  <li>Erreichen Sie Millionen Business- und Privatkunden</li>
                  <li>
                    Optimieren Sie Ihre Vertriebskosten durch eine effiziente digitale
                    Onlinelösung
                  </li>
                  <li>
                    Automatisieren Sie Ihre Buchhaltung mit unserer automatischen
                    Rechnungs- & Gutschriftstellung
                  </li>
                </ul>
                <div className={styles.buttonWrapper}>
                  <Button
                    type="primary"
                    title="Jetzt verkaufen"
                    color="dark_blue"
                    link="//convela.cloud/login/signup-as-vendor"
                  />
                </div>
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.sectionIcon}>
                <img src={sellingIcon} />
              </div>
              <h3 className={styles.sectionTitle}>Verkaufen</h3>
              <h4 className={styles.sectionSubtitle}>Hersteller & Anbieter werden</h4>
              <p className={styles.sectionDescription}>
                Übersicht über tausende Produkte gewährleisten wir, weil wir uns auf eine
                Branche konzentrieren.
                <br className={styles.tabletLaptopBreak} />
                Als <b>Hersteller & Anbieter</b> auf dem{' '}
                <b>zentralen Marktplatz der Bestattung</b> erreichen Sie Ihre Kunden ganz
                einfach und in wenigen Schritten:
              </p>
              <div className={styles.innerSection}>
                <h5>Schritt 1: Registrieren Sie sich als Hersteller & Anbieter</h5>
                <ul>
                  <li>
                    <Button
                      link="//convela.cloud/login/signup-as-vendor"
                      title="Erstellen Sie sich einen Account"
                    />{' '}
                    (Accountinhaber ist eine Privat- oder eine juristische Person, die
                    berechtigt ist, die angebotenen Produkte rechtsgültig zu handeln).
                  </li>
                  <li>
                    Sie benötigen Ihre Umsatzsteuer ID und eine Kopie der
                    Gewerbeanmeldung, damit Sie sich als gewerblichen Händler anmelden
                    können.
                  </li>
                  <li>
                    Hinterlegen Sie eine E-Mail-Adresse, auf die Sie Zugriff haben. Über
                    diese Adresse verifizieren wir Ihre Anmeldung und der Anmeldevorgang
                    kann schnell abgeschlossen werden.
                  </li>
                </ul>

                <h5>Schritt 2: Listen Sie Ihre Produkte</h5>
                <ul>
                  <li>
                    Produkte können Sie sowohl einzeln als auch über Importfunktionen (bei
                    größeren Produktmengen) anlegen.
                  </li>
                  <li>
                    Laden Sie zuerst Ihr Bildmaterial unter dem Navigationspunkt
                    "Verwaltung" → "
                    <Button
                      link="//convela.cloud/management/images"
                      title="Bilder"
                    />
                    " hoch.
                  </li>
                  <li>
                    Legen Sie Ihre Produkte unter dem Navigationspunkt "
                    <Button
                      link="//convela.cloud/management"
                      title="Verwaltung"
                    />
                    " einzeln oder mit der "
                    <Button
                      link="//convela.cloud/management/products-import"
                      title="Importfunktion"
                    />
                    " an.
                  </li>
                  <li>
                    Vervollständigen Sie fehlende Produktinformationen, Produktvarianten,
                    Lieferzeiten & Versandkosten. Beschreiben Sie Ihre Produkte.
                  </li>
                  <li>
                    Lgeen Sie unter dem Punkt "
                    <Button
                      link="//convela.cloud/management/delivery-options"
                      title="Lieferoptionen"
                    />
                    " für Ihre Kategorien übergeordnete Konditionen fest.
                  </li>
                  <li>
                    Vergeben Sie Tags für eine optimierte Such- und Anzeigefunktion. Fehlt
                    Ihnen eine Kategorie oder bestimmte Tags, dann passen wir dies gern
                    für Sie an.
                  </li>
                  <li>
                    Fertig. Veröffentlichen Sie Ihre Produkte, indem Sie in der Liste auf
                    das rote Auge klicken – es wird daraufhin grün.
                  </li>
                </ul>

                <h5>Schritt 3: Vervollständigen Sie Ihr Unternehmensprofil</h5>
                <ul>
                  <li>Hinterlegen Sie Kontakt- und Ansprechpersonen</li>
                  <li>
                    Geben Sie eine gültige Telefonnummer unter Angabe der Landesvorwahl
                    ein.
                  </li>
                  <li>
                    Wählen Sie eine Zahlungsweise für Einzüge (Bankkonto oder
                    Kreditkarte), damit Sie die Funktionen des FMP optimal nutzen zu
                    können. Um Probleme zu vermeiden, stellen Sie sicher, dass Sie eine
                    gültige, nicht abgelaufene Kreditkarte eingeben.
                  </li>
                </ul>

                <h5>Schritt 4: Businesskunden bestellen Ihre Produkte</h5>
                <ul>
                  <li>
                    Derzeit wird das Rechtsgeschäft eines Handels in dieser Branche
                    zumeist zwischen Ihnen und dem Businesskunden abgeschlossen. So wird
                    es mit Fachhandelprodukten auch weiterhin bleiben.
                  </li>
                  <li>
                    Wenn ein Kunde Ihr Produkt wünscht und der Businesskunde dieses
                    Produkt bestellt, informieren wir Sie umgehend. Entweder versenden Sie
                    das Produkt an den Businesskunden direkt oder Sie nutzen den Service
                    des FUNERAL MARKETPLACE TRADE.
                  </li>
                </ul>

                <h5>Schritt 5: Zahlungseingang</h5>
                <ul>
                  <li>
                    Hinterlegen Sie Ihre Bankdaten. Dieses Konto wird für die Auszahlung
                    der Umsätze Ihrer Verkäufe verwendet. Sie müssen Inhaber des
                    angegebenen Kontos sein oder eine entsprechende Kontovollmacht
                    besitzen, sowie Zugang zu den Kontoauszügen für dieses Konto haben.
                  </li>
                </ul>
              </div>
            </div>
            <div className={cn(styles.section, styles.blueSection)}>
              <h3 className={styles.sectionTitle}>Ihr Account</h3>
              <h4 className={styles.sectionSubtitle}>Jetzt kostenlos starten</h4>
              <p className={styles.sectionDescription}>
                Legen Sie sich jetzt einen kostenlosen Account als Hersteller & Anbieter
                an.
                <br />
                Melden Sie sich einfach an und probieren Sie es aus!
              </p>
              <div className={styles.buttonWrapper}>
                <Button
                  type="primary"
                  title="Account erstellen"
                  color="dark_blue"
                  link="//convela.cloud/login/signup-as-vendor"
                />
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.sectionIcon}>
                <img src={plansIcon} />
              </div>
              <h3 className={styles.sectionTitle}>Tarife & Preise</h3>
              <h4 className={styles.sectionSubtitle}>Welche Kosten entstehen Ihnen?</h4>
              <p className={styles.sectionDescription}>
                Ein Hersteller & Anbieter Account ist <b>kostenlos</b>. Sie können eine
                beliebige Menge an Produkten anlegen und alle Funktionen stehen Ihnen zum
                Verkauf Ihrer Produkte zur Verfügung. Kosten fallen erst an, wenn Sie
                Umsätze auf dem Marktplatz generieren. Dies gilt für alle Produkte, die
                über den Marketplace gehandelt werden. Erst wenn Sie eine Bestellung
                erhalten haben, fällt eine Verkaufsprovision an, die wir von der Zahlung
                automatisch abziehen und einbehalten. Geringe Kosten, geringer Aufwand -
                fair & transparent.
              </p>
              <div className={cn(styles.innerSection, styles.categoryPricing)}>
                <div className={styles.innerSectionIcon}>
                  <img src={commissionIcon} />
                </div>
                <h5>Verkaufsprovision</h5>
                <p>
                  Die Provision berechnet sich anhand des Umsatzes. Der Umsatz ist der
                  netto Artikelpreis plus Versand, zzgl. Umsatzsteuer. Die Höhe der
                  Provision variiert je nach Kategorie des jeweiligen Produkts. Hier
                  finden Sie eine Tabelle mit einigen Produktkategorien und den
                  dazugehörigen Provisionssätzen.
                </p>
                <table className={styles.categoryPricingTable}>
                  <thead>
                    <tr>
                      <th>Kategorie</th>
                      <th>
                        Provision
                        <br />
                        <span style={{ fontWeight: 'normal', fontSize: 14 }}>
                          (vom Umsatz)
                        </span>
                      </th>
                      <th>
                        Übermittlungsgebühr
                        <br />
                        <span style={{ fontWeight: 'normal', fontSize: 14 }}>
                          (vom Umsatz)
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoryPricing.map((category) => (
                      <tr key={category.name}>
                        <td>{category.name}</td>
                        <td>{category.commission ? `${category.commission} %` : ''}</td>
                        <td>{category.transferFee ? `${category.transferFee} %` : ''}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p style={{ fontSize: 14, textAlign: 'center' }}>
                  <i>
                    Dies ist ein Auszug der bestehenden Kategorien.
                    <br />
                    Wenn Sie Ihre Kategorie nicht finden,{' '}
                    <Button
                      link="//convela.cloud/contact/help"
                      style={{ textDecoration: 'underline' }}
                      title="fragen Sie uns einfach an"
                    />
                    .
                  </i>
                </p>
              </div>
              <div className={cn(styles.innerSection, styles.categoryPricing)}>
                <div className={styles.innerSectionIcon}>
                  <img src={dataTransferIcon} />
                </div>
                <h5>Digitale Datensatzvermittlung</h5>
                <p>
                  Digitale Datensätze sind z.B. Profildaten, Dokumente oder Formulare, die
                  über den FMP erstellt oder verschickt werden. Für die <b>Übersendung</b>{' '}
                  eines digitalen Datensatzes erheben wir eine pauschale Gebühr von{' '}
                  <b>0,99 €</b> (netto zzgl. USt.). Wünschen Sie eine <b>Schnittstelle</b>{' '}
                  zu Ihrem System, sprechen Sie uns gern an – wir bieten eine RESTful API
                  an. Einmalige Einrichtungskosten, sowie Paketlösungen können vereinbart
                  werden.
                </p>
              </div>
              <div className={cn(styles.innerSection, styles.categoryPricing)}>
                <div className={styles.innerSectionIcon}>
                  <img src={refundsIcon} />
                </div>
                <h5>Erstattungskosten</h5>
                <p>
                  Wenn Sie eine Erstattung für eine Bestellung veranlassen, für die Sie
                  bereits bezahlt wurden, erstatten wir Ihnen die prozentuale
                  Verkaufsgebühr für die Artikel abzüglich einer Bearbeitungsgebühr für
                  Erstattungen in Höhe von 5,00 € oder, falls niedriger, 20 % der
                  prozentualen Verkaufsgebühr.
                  <br />
                  <br />
                  Wenn Sie zum Beispiel einem Kunden den Gesamtverkaufspreis von 10,00 €
                  für einen Artikel in einer Kategorie mit einer prozentualen
                  Verkaufsgebühr von 15 % erstatten, beträgt die Bearbeitungsgebühr für
                  Erstattungen 0,30 € (10,00 € x 15 % prozentuale Verkaufsgebühr = 1,50
                  €).
                  <br />
                  <br />
                  Wenn Sie hierzu Fragen haben, sprechen Sie uns bitte an.
                </p>
              </div>
              <div className={cn(styles.innerSection, styles.categoryPricing)}>
                <div className={styles.innerSectionIcon}>
                  <img src={paymentIcon} />
                </div>
                <h5>Bezahlfunktionen</h5>
                <span>
                  <b>Ihre Daten sind geschützt und sicher.</b>
                </span>
                <br />
                <p>
                  Profitieren Sie vom großen Vorteil der digitalen Bezahlfunktionen:
                  Sicherheit. Denn ein Produkt wird bestellt und gleich bezahlt. Zudem
                  bieten Online-Bezahlfunktion einen hohen Einkaufskomfort, den heutige
                  und zukünftige Kunden zu schätzen wissen.
                  <br />
                  <br />
                  Für den Service der etablierten Online-Bezahlfunktionen via{' '}
                  <b>Kreditkarte</b>, <b>SOFORT-Überweisung</b> oder{' '}
                  <b>SEPA-Lastschrift</b> fällt eine Transaktionsgebühr von 4% (vom
                  Gesamtverkaufswert) + 0,35€ pro Transaktion an, die der Käufer trägt.
                  Für Sie fallen <b>keine Kosten</b> an.
                </p>
              </div>
            </div>

            <div className={cn(styles.section, styles.blueSection)}>
              <h3 className={styles.sectionTitle}>Ihr Account</h3>
              <h4 className={styles.sectionSubtitle}>Jetzt kostenlos starten</h4>
              <p className={styles.sectionDescription}>
                Legen Sie sich jetzt einen kostenlosen Account als Hersteller & Anbieter
                an.
                <br />
                Melden Sie sich einfach an und probieren Sie es aus!
              </p>
              <div className={styles.buttonWrapper}>
                <Button
                  type="primary"
                  title="Account erstellen"
                  color="dark_blue"
                  link="//convela.cloud/login/signup-as-vendor"
                />
              </div>
            </div>
            <div className={cn(styles.section, styles.contactSection)} id="contact">
              <h3 className={styles.sectionTitle}>Kontakt</h3>
              <h4 className={styles.sectionSubtitle}>
                Fragen, Anregungen, Kritik?
                <br />
                Gern.
              </h4>
              <Contacts />
            </div>
            <div id="info">
              <InfoSection backToToplink="/vorsorgen-und-verfuegen/#page-start" />
            </div>
          </PageContent>
        </ContentContainer>
      </ParallaxProvider>
    </Layout>
  );
};

export default Pricing;
