const categoryPricing = [
  { name: 'Särge', commission: 7 },
  { name: 'Urnen', commission: 15 },
  { name: 'Floristik', commission: 10 },
  { name: 'Schmuck', commission: 20 },
  { name: 'Kerzen', commission: 15 },
  { name: 'Trauerredner, Musik', commission: 10 },
  { name: 'Kondolenzmaterialien', commission: 7 },
  { name: 'Dekorationen', commission: 10 },
  { name: 'Digitaler Nachlass', commission: 15 },
  { name: 'Grabsteine', commission: 10 },
  { name: 'Trauerflor', commission: 15 },
  { name: 'Foto-Trauerbücher', commission: 15 },
  { name: 'Überführungen', commission: 10 },
  { name: 'Trauerhallen', commission: 10 },
  { name: 'Abschiedsräume', commission: 10 },
  { name: 'Trauercafés', commission: 5 },
  { name: 'Friedhöfe', transferFee: 3 },
  { name: 'Friedwälder', transferFee: 3 },
  { name: 'Krematorien', transferFee: '1,5' },
  { name: 'Edelsteinbestattungen', commission: 20 },
  { name: 'Erinnerungsstücke', commission: 20 },
  { name: 'Literatur', commission: 20 },
  { name: 'Charity', commission: 0 },
  { name: 'Ausstellungen', commission: 10 },
  { name: 'Marketing', commission: 20 },
  { name: 'Weiterbildungen', commission: 20 },
  { name: 'Schaufenstergestaltungen', commission: 15 },
  { name: 'Pietätswaren / Wäsche', commission: 10 }
];

export default categoryPricing;
